<template>
    <div class="projects">
        <h1>{{$t('nav.projects')}}</h1>
        <CaseIntro case-image="hbn-titlecard" title="HBN Law & Tax" chapeau="UI/UX design & Front End Development" route="projects/hbn" />
        <CaseIntro case-image="fast-fluid-titlecard" title="Fast & Fluid Design System" chapeau="UI/UX design" route="projects/fast-fluid" is-flipped />
        <CaseIntro case-image="all-united-titlecard" title="All United Club Software" chapeau="UI/UX design" route="projects/all-united" />
     </div>
</template>
<script>
// @ is an alias to /src
import CaseIntro from '@/components/CaseIntro.vue'

export default {
  name: 'Projects',
  components: {
    CaseIntro
  }
}
</script>

<style lang="scss" scoped>
  .projects {
    @include margin(null auto);
    max-width: 1200px;
  }
</style>